<template>
  <section>
    <el-form
      ref="form"
      label-position="right"
      status-icon
      :model="form"
      :rules="rules"
      label-width="180px"
      style="width: 95%; overflow: auto"
    >
      <el-form-item label="结果照片" prop="resultUrl">
        <el-image
          :src="sampleDetail.resultUrl"
          title="可点击放大查看"
          :preview-src-list="[sampleDetail.resultUrl]"
          style="height: 300px"
        >
        </el-image>
      </el-form-item>

      <el-form-item label="检测标准">
        <ul
          v-if="
            sampleDetail.sampleTestingStandardsVO &&
            sampleDetail.sampleTestingStandardsVO[0]
          "
          style="margin-left: 20px"
        >
          <li
            v-for="item in sampleDetail.sampleTestingStandardsVO[0]
              .standardsLevels"
          >
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 5px;
              "
            >
              <span style="margin-right: 30px; font-size: 18px">
                级别:{{ item.level }}
              </span>
              <span
                style="
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  display: inline-block;
                  border: 1px black solid;
                "
                :style="{ backgroundColor: `rgb(${item.rgb})` }"
              ></span>
            </div>
            <div style="text-align: left; font-size: 18px">
              描述:{{ item.remark }}
            </div>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="合格级别">
        <div
          v-if="
            sampleDetail.sampleTestingStandardsVO &&
            sampleDetail.sampleTestingStandardsVO[0]
          "
          style="font-size: 34px; font-weight: 700; color: green"
        >
          {{ sampleDetail.sampleTestingStandardsVO[0].qualifiedLevel }}
        </div>
      </el-form-item>
      <el-form-item
        v-if="
          sampleDetail.sampleTestingStandardsVO &&
          sampleDetail.sampleTestingStandardsVO[0]
        "
        label="结果级别"
        prop="level"
      >
        <el-radio-group v-model="form.level">
          <el-radio
            v-for="(item, index) in sampleDetail.sampleTestingStandardsVO[0]
              .standardsLevels"
            :key="index"
            :label="index + 1"
            >{{ index + 1 }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="rmk">
        <el-input
          type="textarea"
          placeholder="请填写备注"
          v-model="form.rmk"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { judgmentResult, getSampleDetail } from '@/api/public.js'
export default {
  props: ['row'],
  data() {
    return {
      form: {
        pipelineId: '',
        level: '',
        rmk: '',
      },
      sampleDetail: {}, // 样品详情
      rules: {
        level: [
          { required: true, message: '结果级别不得为空', trigger: 'blur' },
        ],
      },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.form.pipelineId = this.row ? this.row.pipelineId : this.form.pipelineId
    this.form.result = this.row ? this.row.result : this.form.result
    this.$nextTick(() => {
      this.getSampleInfo()
    })
  },

  methods: {
    close() {
      this.$emit('handleClose')
    },
    async getSampleInfo() {
      await getSampleDetail({ param: this.row.sampleId }).then(res => {
        if (res.success) {
          this.sampleDetail = res.data
        } else {
          this.$message({
            message: '获取结果图片失败，请重试',
            type: 'error',
          })
          this.$emit('handleClose')
        }
      })
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$confirm('确认提交该结果吗？').then(async () => {
            // console.log(this.form, 'form')
            await judgmentResult({ param: this.form }).then(res => {
              if (res.success) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })
                this.$emit('handleClose')
              } else {
                this.$message({
                  message: res.msg,
                  type: 'fail',
                })
              }
            })
          })
        }
      })
    },
  },
}
</script>
<style scoped></style>
