var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('el-form',{ref:"form",staticStyle:{"width":"95%","overflow":"auto"},attrs:{"label-position":"right","status-icon":"","model":_vm.form,"rules":_vm.rules,"label-width":"180px"}},[_c('el-form-item',{attrs:{"label":"结果照片","prop":"resultUrl"}},[_c('el-image',{staticStyle:{"height":"300px"},attrs:{"src":_vm.sampleDetail.resultUrl,"title":"可点击放大查看","preview-src-list":[_vm.sampleDetail.resultUrl]}})],1),_c('el-form-item',{attrs:{"label":"检测标准"}},[(
          _vm.sampleDetail.sampleTestingStandardsVO &&
          _vm.sampleDetail.sampleTestingStandardsVO[0]
        )?_c('ul',{staticStyle:{"margin-left":"20px"}},_vm._l((_vm.sampleDetail.sampleTestingStandardsVO[0]
            .standardsLevels),function(item){return _c('li',[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center","margin-bottom":"5px"}},[_c('span',{staticStyle:{"margin-right":"30px","font-size":"18px"}},[_vm._v(" 级别:"+_vm._s(item.level)+" ")]),_c('span',{staticStyle:{"width":"40px","height":"40px","border-radius":"50%","display":"inline-block","border":"1px black solid"},style:({ backgroundColor: `rgb(${item.rgb})` })})]),_c('div',{staticStyle:{"text-align":"left","font-size":"18px"}},[_vm._v(" 描述:"+_vm._s(item.remark)+" ")])])}),0):_vm._e()]),_c('el-form-item',{attrs:{"label":"合格级别"}},[(
          _vm.sampleDetail.sampleTestingStandardsVO &&
          _vm.sampleDetail.sampleTestingStandardsVO[0]
        )?_c('div',{staticStyle:{"font-size":"34px","font-weight":"700","color":"green"}},[_vm._v(" "+_vm._s(_vm.sampleDetail.sampleTestingStandardsVO[0].qualifiedLevel)+" ")]):_vm._e()]),(
        _vm.sampleDetail.sampleTestingStandardsVO &&
        _vm.sampleDetail.sampleTestingStandardsVO[0]
      )?_c('el-form-item',{attrs:{"label":"结果级别","prop":"level"}},[_c('el-radio-group',{model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}},_vm._l((_vm.sampleDetail.sampleTestingStandardsVO[0]
            .standardsLevels),function(item,index){return _c('el-radio',{key:index,attrs:{"label":index + 1}},[_vm._v(_vm._s(index + 1))])}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"备注","prop":"rmk"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请填写备注"},model:{value:(_vm.form.rmk),callback:function ($$v) {_vm.$set(_vm.form, "rmk", $$v)},expression:"form.rmk"}})],1),_c('el-form-item',[_c('el-button',{on:{"click":function($event){return _vm.close('form')}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","plain":true},on:{"click":function($event){return _vm.submit('form')}}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }